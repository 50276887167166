// src/fetchWrapper.js
const originalFetch = fetch;

const customFetch = async (...args) => {
  try {
    const response = await originalFetch(...args);

    if (response.status === 401) {
        // Unauthorized, delete token and redirect to login
        localStorage.removeItem('atoken');
        window.location.href = '/login';
    }
    return response;
  } catch (error) {
    localStorage.removeItem('token');
    window.location.href = '/login';
    throw error;
  }
};

export default customFetch;
