import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import customFetch from '../fetch-wrapper';
import Navbar from "./navbar";
import Topbar from "./topbar";

function Shop() {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState("");
  let history = useHistory();
  const [product, setProduct] = useState([]);
  const [cate, setCate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0)
  const [currentDate, setCurrentDate] = useState('')
  const currentYear = new Date().getFullYear();
  const [yearPicker, setYearPicker] = useState([])


  const [databoardData,setDashboardData] = useState(
    {totalSales:0, totalProfit:0,totalSellCount:0}
  )

  useEffect(() =>{
    const currentYear = new Date().getFullYear(); 
    const currentMonth = new Date().getMonth();
    const yearFrom = new Date(currentYear, currentMonth, 1);
    setCurrentDate(formatDate(yearFrom))
    for (let i = 0; i < 12; i++) {
      const date = new Date(currentYear, (currentMonth+1) - i, 1); // Create a date object for the 1st of each month
      const formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      setYearPicker(data => [...data,formattedDate] )
    }


    setLoading(true)
    customFetch(`${process.env.REACT_APP_URL}products?quantity=lt:2`, {
      method: "GET",
      headers: {
        "Authorization":localStorage.getItem("atoken"),  // Correctly set the Content-Type
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        if(res.success) {
           setProduct(res.payload.product)
        }
      })
      .catch((err) => console.log(err));
  },[])


  function loadDashboardStat(dateFrom,dateTo){
    setCurrentDate(dateFrom)
    setLoading(true)
    let url = `${process.env.REACT_APP_URL}orders?status=eq:delivered`
    if(dateFrom !== "" && dateTo !== ""){
       url += `&createdAt=between:${dateFrom},${dateTo}`
    }

    customFetch(url, {
      method: "GET",
      headers: {
        "Authorization":localStorage.getItem("atoken"),  // Correctly set the Content-Type
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        if(res.success && res.payload){
            let sales = 0
            let profit = 0
            let sellCount = 0
            res.payload.order.forEach(element => {
               sales += element.totalPrice
               sellCount += element.orderItems.length;
               element.orderItems.forEach(element => {
                 profit += element.product.sellingprice - element.product.buyingprice
               });
            });
            setDashboardData({totalSales:sales,totalProfit:profit,totalSellCount:sellCount})
        }
      })
      .catch((err) => console.log(err));
  }


  function filterByDate(dateFrom){
    console.log(dateFrom)
    document.getElementById("month-select").value = dateFrom;
    const dateTo = new Date(dateFrom);
    const currentYearFrom = new Date(dateFrom).getFullYear();  // Get the current year
    const currentMonthFrom = new Date(dateFrom).getMonth();   // Get the current month (0-based index)
    // Create the yearFrom (1st day of the current month)
    const yearFrom = new Date(currentYearFrom, currentMonthFrom, 1);  // Set the day to 1
    // Create the yearTo (1st day of the next month)
    const yearTo = new Date(yearFrom);
    yearTo.setMonth(yearTo.getMonth() + 1);  // Add one month
    dateTo.setMonth(dateTo.getMonth());
    setTimeout(()=> {
      loadDashboardStat(formatDate(yearFrom), formatDate(yearTo));
    },10)
  }


  // Format both dates as "YYYY-MM-DD"
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Ensure two digits for month
  const day = String(date.getDate()).padStart(2, '0');  // Ensure two digits for day
  return `${year}-${month}-${day}`;
};

  useEffect(()=> {
    setLoading(true)
    customFetch(`${process.env.REACT_APP_URL}orders?status=pending`, {
      method: "GET",
      headers: {
        "Authorization":localStorage.getItem("atoken"),  // Correctly set the Content-Type
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        if(res.success) {
          setTotalCount(res.payload.order.length)
        }
      })
      .catch((err) => console.log(err));
  },[])


  useEffect(()=>  {
    const currentYear = new Date().getFullYear();  // Get the current year
    const currentMonth = new Date().getMonth();   // Get the current month (0-based index)
    // Create the yearFrom (1st day of the current month)
    const yearFrom = new Date(currentYear, currentMonth, 1);  // Set the day to 1
    // Create the yearTo (1st day of the next month)
    const yearTo = new Date(yearFrom);
    yearTo.setMonth(yearTo.getMonth() + 1);  // Add one month
    // Call loadDashboardStat with formatted dates
    loadDashboardStat(formatDate(yearFrom), formatDate(yearTo));
  },[])

  return (
    <>
      <Navbar />
      <div class="container-fluid py-4 px-5">
        <Topbar />


        {loading && <div id="loader" class="loader-backdrop">
        <div class="loader-spinner"></div>
        </div>}

        <div class="quickUse px-5 mb-4">
          <div class="bg-white rounded py-3 px-5">
            <h4 class="text-center text-secondary mb-5">Quick Use</h4>
            <div class="row text-center px-5 mb-4">
              <div class="col-lg-3" style={{ position: "relative" }}>
                <Link
                  to={"/order"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Order
                </Link>
                <span class="neworderblink">{totalCount}</span>
              </div>
              <div class="col-lg-3">
                <Link
                  to={"/addproduct"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Add Product
                </Link>
              </div>
              <div class="col-lg-3">
                <Link
                  to={"/product"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Product List
                </Link>
              </div>
              <div class="col-lg-3">
                <Link
                  to={"/flash"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Flash Sell
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="itemStock px-5 mb-4">
          <div class="bg-white rounded py-3">
              <div className="container">
                <div className="d-flex justify-content-between">
                <h5>Monthly summary</h5>
                <select name="month" id="month-select" className="form-control w-25" onChange={(e)=> filterByDate(e.target.value)}>
                  {yearPicker.map(item => (
                     <option value={item}>{new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date(item))} {new Date(item).getFullYear()}</option>
                  ))}
                </select>
                </div>
                <br/>


                <div class="row">
                  <div class="col-md-4">
                    <div class="card">
                      <div class="card-header bg-warning text-center text-white">
                          <span><i className="fa fa-dollar"></i> Total Sales in amount</span>
                      </div>
                      <div class="card-body text-center">
                          <h5>{databoardData.totalSales} BDT</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card">
                      <div class="card-header bg-primary text-center text-white">
                        <span><i class="fa fa-sort-numeric-asc"></i> Total products Sales</span>
                      </div>
                      <div class="card-body text-center">
                         <h5>{databoardData.totalSellCount}</h5>
                      </div>
                    </div>
                    </div>
                  <div class="col-md-4">
                    <div class="card">
                      <div class="card-header bg-secondary text-center text-white">
                        <span><i className="fa fa-dollar"></i>Total Profit</span>
                      </div>
                      <div class="card-body text-center">
                          <h5>{databoardData.totalProfit} BDT</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>




        <div class="itemStock px-5 mb-4">
          <div class="bg-white rounded py-3">
            <h5 class="text-center text-secondary mb-3">Item Stock Out Soon</h5>
            <table class="table text-center">
              <thead class="border-bottom border-top">
                <tr>
                  <th scope="col" class="text-secondary fw-normal">
                    Product ID
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Product Name
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Brand
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Category
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Available Stock
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Sell Price
                  </th>
                </tr>
              </thead>
              {product.map((item) => (
                <tr class="border-bottom border-secondary">
                  <td class="text-secondary">{item.id}</td>
                  <td class="text-secondary">
                    <p>{item.productname}</p>
                  </td>
                  <td class="text-secondary">{item.brandname}</td>
                  <td class="text-secondary">
                  {item.category === "1" ? "ইলেক্ট্রনিক ডেকোরেশন" : item.category === "2" ? "মাইক্রোফোন" : "ইলেকট্রনিক্স গ্যাজেট"}
                  </td>
                  <td class="text-secondary">{item.quantity}</td>
                  <td class="text-secondary">{item.sellingprice}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Shop;
